import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setToast } from '../redux/slices/toastSlice';

// const strapiAPIToken = process.env.REACT_APP_STRAPI_TOKEN;
// const strapiAuthToken = localStorage.getItem('token');
const strapiServerURL = process.env.REACT_APP_STRAPI_SERVER_URL;
const getLocalToken = () => {
  const localToken = localStorage?.getItem('token');
  return localToken;
}

const createAxiosInstance = (headers={}, publicApi = false, dispatch) => {

  const handleResponse = (response) => {
    // Handle successful responses here
    // console.log("response:::::interceptor", response)
    return response; // You can modify this based on your needs
  };
  
  const handleError = (error) => {
    // const dispatch = useDispatch();
    // console.log("error from interceptor:::", error)
    // Handle errors here
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401 || error.response.status === 403) {
        // Handle 401 Unauthorized errors here
        console.error('Unauthorized:', error);
        dispatch && dispatch(setToast({ type: 'error', open: true, message: 'Unautherized' }))
        // You might want to perform actions such as logging out the user,
        // clearing tokens, or redirecting to a login page
      }
      console.error('Response Error:', error.response.data);
      return Promise.reject(error);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request Error:', error.request);
      return Promise.reject('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request Setup Error:', error.message);
      return Promise.reject('Error setting up request');
    }
  };


  const strapiAuthToken = getLocalToken();
  const headerObj = {
    ...headers,
    'Authorization': `Bearer ${strapiAuthToken}`
  };
  if (publicApi) {
   delete headerObj.Authorization;
  }
    const instance =  axios.create({
      baseURL: strapiServerURL,
      headers: {
        'Content-Type': 'application/json',
        ...headerObj,
      },
    });
    instance.interceptors.response.use(handleResponse, handleError);

    return instance;
  };
  
  export default createAxiosInstance;