import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { user: null ,organization: null,role: null, acceptedOrgId: null},
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setCurrentOrganizaion: (state, action) => {
      state.organization = action.payload;
    },
    setAcceptedOrgId: (state, action) => {
      state.acceptedOrgId = action.payload;
    }
  },
});

export const { setUser, clearUser, setCurrentOrganizaion, setAcceptedOrgId } = userSlice.actions;
export const currentUser = (state) => state.user.user;
export const currentOrg = (state) => state.user.organization;
export const currentRole = (state) => state.user.role;
export default userSlice.reducer;
