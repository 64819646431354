import { createSlice } from '@reduxjs/toolkit';

const polygonsSlice = createSlice({
  name: 'polygons',
  initialState: { polygons: [],totalContactsInPolygon: null },
  reducers: {
    setPolygonsCoordinates: (state, action) => {
      state.polygons = action.payload;
    },
    clearPolygonsCoordinates: (state) => {
      state.polygons = [];
    },
    setContactCountInPolygon: (state, action) => {
      state.totalContactsInPolygon = action.payload;  
    },
    clearContactCountInPolygon: (state) => {
      state.totalContactsInPolygon = null;  
    }
  },
});

export const { setPolygonsCoordinates, clearPolygonsCoordinates, setContactCountInPolygon, clearContactCountInPolygon } = polygonsSlice.actions;
export const polygonsCoordinates = (state) => state.polygons.polygons;
export const contactCountInPolygons = (state) => state.polygons.totalContactsInPolygon;
export default polygonsSlice.reducer;
