export const createTypography = () => {
    return {
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
      },
      title: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57,
        color: 'rgb(105, 117, 134)'
      },
      button: {
        fontWeight: 700
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.66
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.57
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 700,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase'
      },
      h1: {
        fontWeight: 700,
        fontSize: '2.25rem',
        lineHeight: 1.2
      },
      h2: {
        fontWeight: 700,
        fontSize: '1.625rem',
        lineHeight: 1.5
      },
      h3: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: 1.5
      },
      h4: {
        fontWeight: 700,
        fontSize: '1.125rem',
        lineHeight: 1.333
      },
      h5: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: 1.57
      },
      h6: {
        fontWeight: 700,
        fontSize: '0.67em',
        lineHeight: 1.6
      }
    };
  };