import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

const UnauthorizedAccessPage = () => {
  return (
    <Grid
      container
    >
      <Grid item xs={12} md={12} lg={12} style={{ position: 'absolute', top: '20%', left: '30%' }}>
        <Typography variant="h5" >
          Unauthorized Access
        </Typography>
        <Typography>
          You do not have permission to access this page.
        </Typography>
        <Button variant="outlined" justifyContent='center' color="primary" href="/">
          Go to Home Page
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedAccessPage;
