import { createSlice } from '@reduxjs/toolkit';

const toastMessageSlice = createSlice({
  name: 'toast',
  initialState: { toast: { open: false ,message: '',type: ''} },
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    }
  },
});

export const { setToast } = toastMessageSlice.actions;
export const toastInfo = (state) => state?.toast?.toast;
export default toastMessageSlice.reducer;
