import { BorderRight, ImportantDevices } from '@mui/icons-material';
import {
  createTheme,
  filledInputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paperClasses,
  tableCellClasses,
  listItemIconClasses
} from '@mui/material';

// Used only to create transitions
const muiTheme = createTheme();

export function createComponents(config) {
  const { palette } = config;

  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 600,
          letterSpacing: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
          padding: '8px 22px',
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: '1px',
          // width: '1px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          [`&.${paperClasses.elevation1}`]: {
            boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {

        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          // padding: '32px 24px 16px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        },
        '#nprogress': {
          pointerEvents: 'none'
        },
        '#nprogress .bar': {
          backgroundColor: palette.primary.main,
          height: 3,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000
        }
      }
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       '&::placeholder': {
    //         opacity: 1
    //       }
    //     }
    //   }
    // },
    // MuiInput: {
    //   styleOverrides: {
    //     input: {
    //       fontSize: 14,
    //       fontWeight: 500,
    //       lineHeight: '24px',
    //       '&::placeholder': {
    //         color: palette.text.secondary
    //       }
    //     }
    //   }
    // },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 1,
          overflow: 'hidden',
          borderColor: palette.neutral[200],
          transition: muiTheme.transitions.create([
            'border-color',
            'box-shadow'
          ]),
          '&:hover': {
            backgroundColor: palette.action.hover
          },
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: 'transparent'
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: 'transparent',
            borderColor: palette.primary.main,
            boxShadow: `${palette.primary.main} 0 0 0 1px`
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: palette.error.main,
            boxShadow: `${palette.error.main} 0 0 0 1px`
          }
        },
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          padding: (props) => {
            const padding = props.multiline ? '0px' : '25px 12px 8px';
            return padding;
          },
          // padding: '2px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.2em'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '0px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 16
        }
      }
    }},
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        input: {
          padding: '12px 14px',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: 'none',
          '& + &': {
            marginLeft: 24
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
          padding: '15px 16px',
        },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            backgroundColor: palette.neutral[50],
            color: palette.neutral[700],
            fontSize: 14,
            // fontWeight: 600,
            lineHeight: 1,
            // letterSpacing: 0.5,
            // textTransform: 'uppercase'
          },
          [`& .${tableCellClasses.paddingCheckbox}`]: {
            paddingTop: 4,
            paddingBottom: 4
          },
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '56px'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#F8F9FA',
          color: palette.neutral[700],
        },
        cell: {
          padding: '15px 16px',
          borderBottom: '1px solid #F2F4F7',
          // '&:hover': {
          //   color: 'primary.main'
          // }
        },
        root: {
          backgroundColor:"white",
          // border: '0 !important',
          border: '1px solid rgb(227, 232, 238) !important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none'
          // backgroundColor: '#1c2536',
          // color: 'white'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          [`&.Mui-disabled`]: {
            backgroundColor: 'transparent',
            color: 'black !important'
          },
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff', // White background for the dropdown
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Background color when option is selected
          },
          '&[data-focus="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color when option is focused
          },
        },
        root: {
          backgroundColor: '#ffffff',
          borderRadius:"8px"
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(248, 250, 252)', 
          borderRadius:"8px"
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(248, 250, 252)',
          borderRadius: "8px"
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', // White background for the dropdown menu
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '& .MuiChip-deleteIcon': {
            visibility: 'hidden',
          },
          '&:hover .MuiChip-deleteIcon': {
            visibility: 'visible',
          }
        }
      }
    }
  };
}