import './App.css';

import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@mui/material/styles';

import RouteWrapper from './RouterWrapper';
import { createTheme } from './theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import { currentUser, setUser } from "./redux/slices/userSlice";

const theme = createTheme();

function App() {
  return (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouteWrapper />
        </ThemeProvider>
      </Provider>
    );
}

export default App;
