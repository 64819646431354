import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import toastSlice from './slices/toastSlice';
import moduleConfig from './slices/moduleConfig';
import polygonSlice from './slices/polygonCoordinates';
import taskSlice from './slices/taskSlice';

const config = () => {
  return {
    isProd: ['cg-web.stage.ceegees-inc.com'].includes(window.location.hostname)
  }
}
const store = configureStore({
  reducer: {
    config,
    user: userReducer,
    toast: toastSlice,
    moduleConfig,
    polygons: polygonSlice,
    task: taskSlice,
  },
});
window.gAppStore = store;
console.log(store.getState());

export default store;
