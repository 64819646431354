import { createSlice } from '@reduxjs/toolkit';

const moduleConfig = createSlice({
  name: 'moduleConfig',
  initialState: { moduleConfig: [] },
  reducers: {
    setModuleConfigInfo: (state, action) => {
      state.moduleConfig = action.payload;
    }
  }
});

export const { setModuleConfigInfo } = moduleConfig.actions;
export const moduleConfigInfo = (state) => state.moduleConfig.moduleConfig;
export default moduleConfig.reducer;
